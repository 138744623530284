import { Box, Zoom, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import AreYouACreator from './AreYouACreator';
import Brands from './Brands';
import Hero from './Hero';
import HowItWorks from './HowItWorks';
import NewToElli from './NewToElli';
import OrderUGC from './OrderUGC';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import CreatorsPerks from './CreatorsPerks';
import NoFollowers from './NoFollowers';

const OverviewWrapper = styled(Box)(
  ({}) => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
`
);
const Content = styled('div')(
  ({}) => `
  max-width: 1280px;
  margin: 0 auto;
`
);
const BgColor = styled('div')(
  ({}) => `
  background: #f9f9f9;
  padding-bottom: 10px;
  border-radius: 5px;
`
);

function Home() {
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  // check if location href have # and scroll to that element
  useEffect(() => {
    if (window && window.location.href.includes('#')) {
      const value = window.location.href.split('#')[1];
      if (value === 'confirm_email') {
        enqueueSnackbar(t('Email Confirmed'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          TransitionComponent: Zoom
        });
      }
    }
  }, []);

  return (
    <OverviewWrapper>
      <Helmet>
        <title>{t('Elli Creators - Home Page')}</title>
      </Helmet>
      <Header />
      <Content>
        <Hero />
        <BgColor>
          <HowItWorks />

          <NewToElli />
          <OrderUGC />
          <CreatorsPerks />
          <NoFollowers />
          {/* <Brands /> */}
          <AreYouACreator />
        </BgColor>
      </Content>

      <Footer />
    </OverviewWrapper>
  );
}

export default Home;
