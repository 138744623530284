// @ts-nocheck
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import store from 'src/store';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';

import BB_ttf from './Assets/Fonts/BalooBhaijaan2-Regular.ttf'; // Import your BalooBhaijaan2-Regular font files
import BB_eot from './Assets/Fonts/BalooBhaijaan2-Regular.eot';
import BB_woff from './Assets/Fonts/BalooBhaijaan2-Regular.woff';
import BB_woff2 from './Assets/Fonts/BalooBhaijaan2-Regular.woff2';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import './i18n/i18n';
import CustomLoading from './components/CustomLoading';

import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
import { selectLanguage } from './store/ConfigsReducer';

const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://a54d1018689e47cca5d370049c477c98@sentry.ellicreators.com/4',
  release: '1.0.0',
  environment:
    window.location.hostname === 'ellicreators.com'
      ? 'production'
      : window.location.hostname === 'elli.staging.wtmsrv.com'
      ? 'staging'
      : 'development',
  integrations: [
    // new BrowserTracing({
    //   routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
    // })
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/elli\.staging\.wtmsrv\.com/,
        /^https:\/\/api\.elli\.staging\.wtmsrv\.com/,
        /^https:\/\/ellicreators\.com/,
        /^https:\/\/api\.ellicreators\.com/
      ],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ],
  tracesSampleRate: 1.0,
  normalizeDepth: 10
});

const persistor = persistStore(store);

const theme = createTheme(
  (() => {
    const arabicFontSrc = {
      ttf: BB_ttf,
      eot: BB_eot,
      woff: BB_woff,
      woff2: BB_woff2
    };

    return {
      body: {
        backgroundColor: 'red',
        direction: 'rtl'
      },
      typography: {
        fontFamily: 'BalooBhaijaan2-Regular'
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: `
            @font-face {
              font-family: 'BalooBhaijaan2-Regular';
              src: url(${arabicFontSrc.ttf}) format('truetype'),
                  url(${arabicFontSrc.eot}) format('truetype'),
                  url(${arabicFontSrc.woff}) format('truetype'),
                  url(${arabicFontSrc.woff2}) format('truetype');
              font-weight: 300;
              font-style: normal;
              font-display: auto;
            }
        `
        }
      }
    };
  })()
);

ReactDOM.render(
  <HelmetProvider>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SidebarProvider>
            <BrowserRouter>
              <ScrollTop />
              {/* <AuthProvider> */}
              <App />
              {/* </AuthProvider> */}
            </BrowserRouter>
          </SidebarProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
