import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Blog from 'src/content/Blog';
import CreatorComprehensiveHandbook from 'src/content/Blog/CreatorComprehensiveHandbook';
import Ugc101 from 'src/content/Blog/Ugc101';
import Home from 'src/content/Home';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

// const Home = Loader(lazy(() => import('src/content/Home')));
const AboutUs = Loader(lazy(() => import('src/content/AboutUs')));
const ContactUs = Loader(lazy(() => import('src/content/ContactUs')));
const Pricing = Loader(lazy(() => import('src/content/Pricing')));
const Checkout = Loader(lazy(() => import('src/content/Pricing/Checkout')));
const PrivacyPolicy = Loader(lazy(() => import('src/content/PrivacyPolicy')));
const TermsAndConditions = Loader(
  lazy(() => import('src/content/TermsAndConditions'))
);
const BecomeACreator = Loader(lazy(() => import('src/content/BecomeACreator')));
const BecomeACreatorForm = Loader(
  lazy(() => import('src/content/BecomeACreator/Form'))
);
const Creators = Loader(lazy(() => import('src/content/Creators')));
const FAQ = Loader(lazy(() => import('src/content/FAQ')));

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);

const baseRoutes = [
  {
    path: '/',
    element: <Home />
  },
  {
    path: 'overview',
    element: <Navigate to="/" replace />
  },
  {
    path: '/become-a-creator',
    element: <BecomeACreator />
  },
  {
    path: 'become-a-creator',
    element: <Navigate to="/become-a-creator" replace />
  },
  {
    path: '/about-us',
    element: <AboutUs />
  },
  {
    path: 'about-us',
    element: <Navigate to="/about-us" replace />
  },
  {
    path: '/blog',
    element: <Blog />
  },
  // {
  //   path: 'blog',
  //   element: <Navigate to="/blog" replace />
  // },
  {
    path: '',
    children: [
      {
        path: '',
        element: <Navigate to="/blog" replace />
      },
      {
        path: 'blog/ugc-101',
        element: <Ugc101 />
      },
      {
        path: 'blog/creators-comprehensive-handbook',
        element: <CreatorComprehensiveHandbook />
      }
    ]
  },
  {
    path: '/contact-us',
    element: <ContactUs />
  },
  {
    path: 'contact-us',
    element: <Navigate to="/contact-us" replace />
  },
  {
    path: '/pricing',
    element: <Pricing />
  },
  {
    path: 'pricing',
    element: <Navigate to="/pricing" replace />
  },
  {
    path: '/checkout',
    element: <Checkout />
  },
  {
    path: 'checkout',
    element: <Navigate to="/checkout" replace />
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolicy />
  },
  {
    path: 'privacy-policy',
    element: <Navigate to="/privacy-policy" replace />
  },
  {
    path: '/terms-and-conditions',
    element: <TermsAndConditions />
  },
  {
    path: 'terms-and-conditions',
    element: <Navigate to="/terms-and-conditions" replace />
  },
  {
    path: '/become-a-creator/form',
    element: <BecomeACreatorForm />
  },
  {
    path: 'become-a-creator/form',
    element: <Navigate to="/become-a-creator/form" replace />
  },
  {
    path: '/creators',
    element: <Creators />
  },
  {
    path: 'creators',
    element: <Navigate to="/creators" replace />
  },
  {
    path: '/faq',
    element: <FAQ />
  },
  {
    path: 'faq',
    element: <Navigate to="/faq" replace />
  },
  {
    path: '*',
    element: <Status404 />
  },
  {
    path: 'status',
    children: [
      {
        path: '500',
        element: <Status500 />
      },
      {
        path: 'maintenance',
        element: <StatusMaintenance />
      },
      {
        path: 'coming-soon',
        element: <StatusComingSoon />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  }
];

export default baseRoutes;
