import {
  Box,
  styled,
  Typography,
  useTheme,
  Container,
  Grid
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Footer from 'src/components/Footer';
import Header from 'src/components/Header';
import Brands from '../Home/Brands';
import { useNavigate } from 'react-router-dom';
import { AccessTime, Event } from '@mui/icons-material';

const OverviewWrapper = styled(Box)(
  ({ theme }) => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
`
);
const Content = styled('div')(
  ({ theme }) => `
   margin-top: 100px;
   padding: 30px 0;
`
);

const ContentContainer = styled('div')(
  ({ theme }) => `
  flex-direction: column;
  justify-content: space-around;
  display: flex;
  padding: 1rem 1.8rem 2rem 2rem;
`
);
const CustomImg = styled('img')(
  ({ theme }) => `
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
`
);
const CoverImageBox = styled('div')(
  ({ theme }) => `
  width: 100%;
  padding-top: 66%;
  position: relative;
  overflow: hidden;
`
);

const CustomGradient = styled('span')(
  ({ theme }) => `
  background: -webkit-linear-gradient(360deg,#020024 0%,${theme.colors.primary.main} 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
);
const CustomArticle = styled('article')(
  ({ theme }) => `
  height: 100%;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  transition: box-shadow .2s;
`
);
const Tag = styled('div')(
  ({ theme }) => `
  display: flex;
  
`
);
const TagLink = styled('div')(
  ({ theme }) => `
  background-color: #f7f7f7;
  padding: 10px 20px;
  border-radius: 20px;
`
);
const TagText = styled('div')(
  ({ theme }) => `
  color: ${theme.colors.primary.main};
  font-weight: 700;
`
);
const Title = styled('a')(
  ({ theme }) => `
  margin-bottom: 0.5rem;
  text-decoration: none;
  display: block;
  color: #333;
`
);
const FooterText = styled('div')(
  ({ theme }) => `
  grid-column-gap: 1rem;
  padding-top: 0.8rem;
  display: flex;
`
);
const Text = styled('div')(
  ({ theme }) => `
  grid-column-gap: 0.3rem;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: .5fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: flex;
`
);
const BlogContainer = styled('div')(
  ({ theme }) => `
  
`
);
const CreatorsBg = styled('div')(
  ({ theme }) => `
  background: #f9f9f9;
  margin-top: 80px;
  padding-bottom: 50px;
`
);
const BlogContent = styled('div')(
  ({ theme }) => `
  grid-column-gap: 1.3rem;
  grid-row-gap: 1.3rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
`
);
const Headline = styled(Typography)(
  ({ theme }) => `
  font-size: 30px;
  font-weight: 700;
`
);

function Blog() {
  const { t }: { t: any } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <OverviewWrapper>
      <Helmet>
        <title>{t('Elli Creators - Blog')}</title>
      </Helmet>
      <Header />

      <Content>
        <Container>
          <Box mb={3}>
            <Typography variant="h1">
              <CustomGradient>{t('Blog')}</CustomGradient>
            </Typography>
            <Typography fontSize={15} mt={1}>
              {t('Discover proven tips and strategies to leverage user-generated content and creator partnerships for authentic and impactful marketing.')}
            </Typography>
          </Box>

          <BlogContainer>
            <Headline mb={2}>{t('Key Concepts for First-Timers')}</Headline>

            <Grid container direction="row" alignItems="stretch" spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <Box>
                  <CustomArticle>
                    <a href="/blog/ugc-101">
                      <CoverImageBox>
                        <CustomImg alt="" src="/static/images/blog/ugc.jpeg" />
                      </CoverImageBox>
                    </a>
                    <ContentContainer>
                      {/* <Tag>
                        <TagLink>
                          <TagText>UGC</TagText>
                        </TagLink>
                      </Tag> */}
                      <Title>
                        <h3>{t('User-generated content 101')}</h3>
                      </Title>
                      <div>
                        {t("The Beginner's Guide to Slaying It With user-generated content")}
                      </div>
                      <FooterText>
                        <Text>
                          <Event />
                          <div>{t('Sep')} 15, 2023</div>
                        </Text>
                        <Text>
                          <AccessTime />
                          <div>5</div>
                          <div>{t('Min')}</div>
                        </Text>
                      </FooterText>
                    </ContentContainer>
                  </CustomArticle>
                </Box>
              </Grid>
            </Grid>
          </BlogContainer>
        </Container>

        <CreatorsBg>
          <Container>
            <Headline mb={2} pt={4}>
              {t('Creators, This is For You')}
            </Headline>
            <Grid container direction="row" alignItems="stretch" spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <Box>
                  <CustomArticle>
                    <a href="/blog/creators-comprehensive-handbook">
                      <CoverImageBox>
                        <CustomImg
                          alt=""
                          src="/static/images/blog/creator.jpeg"
                        />
                      </CoverImageBox>
                    </a>
                    <ContentContainer>
                      {/* <Tag>
            <TagLink>
              <TagText>UGC</TagText>
            </TagLink>
          </Tag> */}
                      <Title>
                        <h3>
                          {t('Crafting Remarkable User Generated Content (UGC) Campaigns; A Comprehensive Handbook')}
                        </h3>
                      </Title>
                      <div>
                        {t('In the ever-evolving landscape of digital marketing, UGC has emerged as a game-changer.')}
                      </div>
                      <FooterText>
                        <Text>
                          <Event />
                          <div>{t('Sep')} 15, 2023</div>
                        </Text>
                        <Text>
                          <AccessTime />
                          <div>3</div>
                          <div>{t('Min')}</div>
                        </Text>
                      </FooterText>
                    </ContentContainer>
                  </CustomArticle>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </CreatorsBg>
      </Content>
      <Footer />
    </OverviewWrapper>
  );
}

export default Blog;
