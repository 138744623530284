import { RouteObject } from 'react-router';

// import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';
import BaseLayout from 'src/layouts/BaseLayout';
import TopNavigationLayout from 'src/layouts/TopNavigationLayout';

import dashboardsRoutes from './dashboards';
import accountRoutes from './account';
import baseRoutes from './base';

const router: RouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: 'dashboard',
    element: (
      // <Authenticated>
        <TopNavigationLayout />
      // </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="dashboard" replace />
      },
      {
        path: '',
        children: dashboardsRoutes
      }
    ]
  }
];

export default router;
