const enJSON = {
  'Become a creator': 'Become a creator',
  Creators: 'Creators',
  Pricing: 'Pricing',
  'About us': 'About us',
  'Contact us': 'Contact us',
  'Log in / Sign up': 'Log in / Sign up',
  'Unlock the Power of Collaborating with Vetted Creators':
    'Unlock the Power of Collaborating with Vetted Creators',
  "Wanna take your marketing up a notch? It's time to tap into real deal":
    "Wanna take your marketing up a notch? It's time to tap into real deal",
  'User Generated Content (UGC)': 'User Generated Content (UGC)',
  "I'm talking authentic videos created just for you by awesome creators":
    "I'm talking authentic videos created just for you by awesome creators",
  'The best part? You can work with a bunch of top-notch creators at once for an affordable price. Starting from a $70 per high-quality video. An absolute steal!':
    'The best part? You can work with a bunch of top-notch creators at once for an affordable price. Starting from a $70 per high-quality video. An absolute steal!',
  'JOIN NOW': 'JOIN NOW',
  "I'm a brand": "I'm a brand",
  "I'm a creator": "I'm a creator",
  Task: 'Task',
  'Create a task': 'Create a task',
  'Fill out the task in a few minutes by selecting the relevant options for your need.':
    'Fill out the task in a few minutes by selecting the relevant options for your need.',
  'Match Creators': 'Match Creators',
  'Get matched to the right creators': 'Get matched to the right creators',
  'We will match you to the perfect creator profiles that fit with your brand and campaign goal. Review the selection and start your collaborations':
    'We will match you to the perfect creator profiles that fit with your brand and campaign goal. Review the selection and start your collaborations',
  'Get content': 'Get content',
  'Receive content from your creators. Request an edit or click approve, and download the content':
    'Receive content from your creators. Request an edit or click approve, and download the content',
  'Start working with creators in 3 easy steps':
    'Start working with creators in 3 easy steps',
  'Every step is integrated into the platform to streamline your collaborations':
    'Every step is integrated into the platform to streamline your collaborations',
  'Try it out': 'Try it out',
  'Welcome to Elli!': 'Welcome to Elli!',
  Get: 'Get',
  '15% OFF your first UGC Video order!': '15% OFF your first UGC Video order!',
  'Claim my discount!': 'Claim my discount!',
  'No followers, no worries': 'No followers, no worries',
  'We bring brands, you bring content': 'We bring brands, you bring content',
  "All you need is a smartphone or a working camera, and you're all set to monetize your creative work. Get started today and turn your passion into profit":
    "All you need is a smartphone or a working camera, and you're all set to monetize your creative work. Get started today and turn your passion into profit",
  'Authentic UGC Videos Tailored to': 'Authentic UGC Videos Tailored to',
  'Your Product': 'Your Product',
  "Don't wait any longer - sign up and start benefiting from the impact of UGC today! What's more, we proudly offer a 30-day money-back guarantee, ensuring you have nothing to lose":
    "Don't wait any longer - sign up and start benefiting from the impact of UGC today! What's more, we proudly offer a 30-day money-back guarantee, ensuring you have nothing to lose",
  'Order UGC Video Now!': 'Order UGC Video Now!',
  'Love creating content?': 'Love creating content?',
  'Creator Perks': 'Creator Perks',
  'Extra earnings': 'Extra earnings',
  'The greater the number of videos you create, the higher your earning potential becomes':
    'The greater the number of videos you create, the higher your earning potential becomes',
  'Free products': 'Free products',
  'All the products that brands send for you to work with will remain in your possession even after the job is completed':
    'All the products that brands send for you to work with will remain in your possession even after the job is completed',
  'No price negotiations': 'No price negotiations',
  "You'll never find yourself in a position to negotiate rates. We offer fixed prices only, ensuring a straightforward and transparent experience for you":
    "You'll never find yourself in a position to negotiate rates. We offer fixed prices only, ensuring a straightforward and transparent experience for you",
  'Trusted by the Leading Brands': 'Trusted by the Leading Brands',
  'ARE YOU A CREATOR?': 'ARE YOU A CREATOR?',
  'Get creative. Enjoy the process': 'Get creative. Enjoy the process',
  'Earn while doing what you love': 'Earn while doing what you love',
  'Download the app and start earning today':
    'Download the app and start earning today',
  Dashboard: 'Dashboard',
  Profile: 'Profile',
  'Sign out': 'Sign out',
  'Elli Creators - Home Page': 'Elli Creators - Home Page',
  'Subscribe to the newsletter': 'Subscribe to the newsletter',
  'and stay in the know': 'and stay in the know',
  'The email provided should be a valid email address':
    'The email provided should be a valid email address',
  'Field is required': 'Field is required',
  'Subscribed to newsletter successfully!':
    'Subscribed to newsletter successfully!',
  'Email Address': 'Email Address',
  Blogs: 'Blogs',
  FAQs: 'FAQs',
  'Terms & conditions': 'Terms & conditions',
  'Privacy policy': 'Privacy policy',
  '© 2024 Elli. All rights reserved': '© 2024 Elli. All rights reserved',
  "Answers from product preference form": "Answers from product preference form",
  "Use these answers to ship the right products to the creators": "Use these answers to ship the right products to the creators",
  "Close": "Close",
  "© 2022 Elli. All rights reserved": "© 2022 Elli. All rights reserved",
  "Algeria": "Algeria",
  "Bahrain": "Bahrain",
  "Egypt": "Egypt",
  "Iraq": "Iraq",
  "Jordan": "Jordan",
  "Kuwait": "Kuwait",
  "Lebanon": "Lebanon",
  "Libya": "Libya",
  "Morocco": "Morocco",
  "Oman": "Oman",
  "Qatar": "Qatar",
  "Saudi Arabia": "Saudi Arabia",
  "Sudan": "Sudan",
  "Tunisia": "Tunisia",
  "UAE": "UAE",
  "'s profile": "'s profile",
  "for": "for",
  "You'll be able to see the 3 most recent videos created by the creator on the Elli platform as well as their Video Pitch. In case when the creator is new on Elli, you'll be able to see just their Video pitch to check how they act in front of the camera and see if you like their style of filming.": "You'll be able to see the 3 most recent videos created by the creator on the Elli platform as well as their Video Pitch. In case when the creator is new on Elli, you'll be able to see just their Video pitch to check how they act in front of the camera and see if you like their style of filming.",
  "Creator statistics": "Creator statistics",
  "About creator": "About creator",
  "Creator has been rejected successfully!": "Creator has been rejected successfully!",
  "Reject": "Reject",
  "Creator has been approved successfully!": "Creator has been approved successfully!",
  "Approve": "Approve",
  "Please check your email!": "Please check your email!",
  "Claim my discount": "Claim my discount",
  "Get 15% OFF discount code!": "Get 15% OFF discount code!",
  "Edit product": "Edit product",
  "New product": "New product",
  "Please fill out all fields before adding a new one.": "Please fill out all fields before adding a new one.",
  "Should be a valid url": "Should be a valid url",
  "Product edited successfully!": "Product edited successfully!",
  "Physical product": "Physical product",
  "Add a picture for your product": "Add a picture for your product",
  "Product description": "Product description",
  "Product name": "Product name",
  "This product will be a giveaway for the creator": "This product will be a giveaway for the creator",
  "I want to offer the creator a discount": "I want to offer the creator a discount",
  "What do you intend to offer the creator as a giveaway?": "What do you intend to offer the creator as a giveaway?",
  "Product price": "Product price",
  "Product URL": "Product URL",
  "How to access your digital product or service?": "How to access your digital product or service?",
  "Product access link": "Product access link",
  "How can the creator access the product? (website, App store link, etc.)": "How can the creator access the product? (website, App store link, etc.)",
  "Product access instructions": "Product access instructions",
  "Use product preferences form to request additional information": "Use product preferences form to request additional information",
  "Submit": "Submit",
  "Required": "Required",
  "Thank you for the rating!": "Thank you for the rating!",
  "Rate creator": "Rate creator",
  "Performance": "Performance",
  "Video quality": "Video quality",
  "Audio quality": "Audio quality",
  "Turnaround time": "Turnaround time",
  "Comment": "Comment",
  "TO DELIVER": "TO DELIVER",
  "Creator have to share the video in their feeds and their SM stories": "Creator have to share the video in their feeds and their SM stories",
  "Requested edits": "Requested edits",
  "Comments": "Comments",
  "Creator preference": "Creator preference",
  "Requirements": "Requirements",
  "VIDEO TYPE": "VIDEO TYPE",
  "DURATION": "DURATION",
  "seconds": "seconds",
  "ASPECT RATIO": "ASPECT RATIO",
  "Schedule visit": "Schedule visit",
  "Visit appointments": "Visit appointments",
  "Scenario": "Scenario",
  "What to mention": "What to mention",
  "Content examples": "Content examples",
  "Additional details": "Additional details",
  "A NOTE FROM THE BRAND": "A NOTE FROM THE BRAND",
  "Approve video?": "Approve video?",
  "Application has been approved successfully!": "Application has been approved successfully!",
  "This video will become final and you will be able to download it.": "This video will become final and you will be able to download it.",
  "Yes, approve video": "Yes, approve video",
  "Elli Creators - About Us": "Elli Creators - About Us",
  "OUR MISSION": "OUR MISSION",
  "Releasing the creativity of the world": "Releasing the creativity of the world",
  "Elli is a brand connection platform that connects businesses with creators. We believe that every brand has a unique story to tell, and we want to help them share that story with the world. Our platform makes it easy for brands to find the right creators for their campaigns, and for creators to connect with brands that align with their values and style.": "Elli is a brand connection platform that connects businesses with creators. We believe that every brand has a unique story to tell, and we want to help them share that story with the world. Our platform makes it easy for brands to find the right creators for their campaigns, and for creators to connect with brands that align with their values and style.",
  "Our platform makes it easy to find the perfect match for your brand, and our team of experienced marketers and creative professionals are on hand to ensure the process is seamless. We work closely with you to understand your goals and objectives, and then match you with creators who align with your values and style.": "Our platform makes it easy to find the perfect match for your brand, and our team of experienced marketers and creative professionals are on hand to ensure the process is seamless. We work closely with you to understand your goals and objectives, and then match you with creators who align with your values and style.",
  "With Elli, you can tell your story in a unique and authentic way, and reach new audiences through the power of creativity. So why not unlock your brand's potential and join us on our mission to connect businesses with the world's best creators.": "With Elli, you can tell your story in a unique and authentic way, and reach new audiences through the power of creativity. So why not unlock your brand's potential and join us on our mission to connect businesses with the world's best creators.",
  "THEREFORE": "THEREFORE",
  "If you're a brand looking to connect with creators, or a creator looking for new opportunities, we would love to hear from you. Contact us today to learn more about how Elli can help your business succeed.": "If you're a brand looking to connect with creators, or a creator looking for new opportunities, we would love to hear from you. Contact us today to learn more about how Elli can help your business succeed.",
  "What are you": "What are you",
  "waiting for?": "waiting for?",
  "Become one of the brands": "Become one of the brands",
  "utilizing creator marketing": "utilizing creator marketing",
  "Join us": "Join us",
  "Are you a": "Are you a",
  "Creator?": "Creator?",
  "Get creative. Enjoy the process.": "Get creative. Enjoy the process.",
  "Earn while doing what you love.": "Earn while doing what you love.",
  "Elli Creators - Blog": "Elli Creators - Blog",
  "Blog": "Blog",
  "Discover proven tips and strategies to leverage user-generated content and creator partnerships for authentic and impactful marketing.": "Discover proven tips and strategies to leverage user-generated content and creator partnerships for authentic and impactful marketing.",
  "Key Concepts for First-Timers": "Key Concepts for First-Timers",
  "User-generated content 101": "User-generated content 101",
  "The Beginner's Guide to Slaying It With user-generated content": "The Beginner's Guide to Slaying It With user-generated content",
  "Sep": "Sep",
  "Min": "Min",
  "Creators, This is For You": "Creators, This is For You",
  "Crafting Remarkable User Generated Content (UGC) Campaigns; A Comprehensive Handbook": "Crafting Remarkable User Generated Content (UGC) Campaigns; A Comprehensive Handbook",
  "In the ever-evolving landscape of digital marketing, UGC has emerged as a game-changer.": "In the ever-evolving landscape of digital marketing, UGC has emerged as a game-changer.",
  "UGC 101": "UGC 101",
  "A Comprehensive Guide, to Excelling with User Generated Content, for Beginners": "A Comprehensive Guide, to Excelling with User Generated Content, for Beginners",
  "Elli Creators - UGC 101": "Elli Creators - UGC 101",
  "Hey! If you're a brand owner I'm sure you're constantly, on the lookout for marketing strategies. Lately theres been some buzz around this concept called user generated content (UGC). What is it and how can you leverage it to boost your business?": "Hey! If you're a brand owner I'm sure you're constantly, on the lookout for marketing strategies. Lately theres been some buzz around this concept called user generated content (UGC). What is it and how can you leverage it to boost your business?",
  "Well you're in luck because I've got all the information you need here in this beginners guide. We'll cover everything, from the basics to techniques so that you can start dominating with UGC.": "Well you're in luck because I've got all the information you need here in this beginners guide. We'll cover everything, from the basics to techniques so that you can start dominating with UGC.",
  "Get ready to level up like a pro!": "Get ready to level up like a pro!",
  "Lets start with the basics. What exactly is UGC?": "Lets start with the basics. What exactly is UGC?",
  "To put it simply UGC refers to any content that is created by your customers or enthusiastic fans. This can include media posts, videos, blog articles and more! Of your brand producing material it's your fans who take the lead.": "To put it simply UGC refers to any content that is created by your customers or enthusiastic fans. This can include media posts, videos, blog articles and more! Of your brand producing material it's your fans who take the lead.",
  "It's, like having your own team of brand advocates doing marketing for you! Now that's definitely something.": "It's, like having your own team of brand advocates doing marketing for you! Now that's definitely something.",
  "Where does this incredible user generated content (UGC) originate from?": "Where does this incredible user generated content (UGC) originate from?",
  "Your message has been received, we will get back to you as soon as possible!": "Your message has been received, we will get back to you as soon as possible!",
  "Elli Creators - Contact Us": "Elli Creators - Contact Us",
  "Say hey!": "Say hey!",
  "First name": "First name",
  "Last name": "Last name",
  "Email": "Email",
  "Company": "Company",
  "How can we help you?": "How can we help you?",
  "Region": "Region",
  "Message": "Message",
  "Elli Creators - Creators": "Elli Creators - Creators",
  "Country": "Country",
  "No data!": "No data!",
  "Take the leap and hire your UGC creator today!": "Take the leap and hire your UGC creator today!",
  "Get started today and hire your first": "Get started today and hire your first",
  "UGC creator": "UGC creator",
  "with a $20 discount!": "with a $20 discount!",
  "Receive a $20 discount code instantly in your inbox! Moreover, take advantage of our 30-day money-back guarantee, ensuring you have absolutely nothing to risk!": "Receive a $20 discount code instantly in your inbox! Moreover, take advantage of our 30-day money-back guarantee, ensuring you have absolutely nothing to risk!",
  "Unlock a $20 discount code!": "Unlock a $20 discount code!",
  "Hire me for your next video": "Hire me for your next video",
  "Videos": "Videos",
  "Total spent": "Total spent",
  "Tasks": "Tasks",
  "Approve Creators": "Approve Creators",
  "creator": "creator",
  "creators": "creators",
  "to approve": "to approve",
  "Approve creators": "Approve creators",
  "creator applied for": "creator applied for",
  "Waiting for creators to apply": "Waiting for creators to apply",
  "Create a task for creators to apply": "Create a task for creators to apply",
  "Usually takes up a few hours": "Usually takes up a few hours",
  "Create task": "Create task",
  "Approve videos": "Approve videos",
  "video": "video",
  "videos": "videos",
  "Approve video": "Approve video",
  "creator submitted a video for": "creator submitted a video for",
  "Waiting for the creators to upload videos": "Waiting for the creators to upload videos",
  "Takes up to 7 days after the product is shipped to creators": "Takes up to 7 days after the product is shipped to creators",
  "No videos to approve": "No videos to approve",
  "Something shows up here once the creator uploads a video. Stay tuned": "Something shows up here once the creator uploads a video. Stay tuned",
  "Ship products": "Ship products",
  "product": "product",
  "products": "products",
  "to ship": "to ship",
  "product to ship for": "product to ship for",
  "All products shipped!": "All products shipped!",
  "No products to ship": "No products to ship",
  "Something shows up here once you approve the creators you wanna work with": "Something shows up here once you approve the creators you wanna work with",
  "Elli Creators - Dashboard": "Elli Creators - Dashboard",
  "Authentic videos from our video content creators": "Authentic videos from our video content creators",
  "How Elli works?": "How Elli works?",
  "Start with your first video task": "Start with your first video task",
  "Add your product and how you'd like to showcase it. We'll guide you every step of the way": "Add your product and how you'd like to showcase it. We'll guide you every step of the way",
  "Start Now": "Start Now",
  "Welcome": "Welcome",
  "These are your analytics stats for today": "These are your analytics stats for today",
  "Complete brand data": "Complete brand data",
  "Card added successfully": "Card added successfully",
  "An error occurred while adding the card": "An error occurred while adding the card",
  "Add Payment Method": "Add Payment Method",
  "Brand data edited saved successfully!": "Brand data edited saved successfully!",
  "Edit brand": "Edit brand",
  "Cover image": "Cover image",
  "Save": "Save",
  "Cancel": "Cancel",
  "Delete": "Delete",
  "Product has been deleted successfully!": "Product has been deleted successfully!",
  "Something went wrong, please try later!": "Something went wrong, please try later!",
  "Go back": "Go back",
  "Digital product": "Digital product",
  "Device mockup or a screenshot": "Device mockup or a screenshot",
  "Brand color": "Brand color",
  "Name": "Name",
  "Type": "Type",
  "Category": "Category",
  "Brand country": "Brand country",
  "Website": "Website",
  "Brand description": "Brand description",
  "Facebook URL": "Facebook URL",
  "Instagram URL": "Instagram URL",
  "Tiktok URL": "Tiktok URL",
  "Youtube URL": "Youtube URL",
  "Twitter URL": "Twitter URL",
  "Linkedin URL": "Linkedin URL",
  "Snapchat URL": "Snapchat URL",
  "Amazon URL": "Amazon URL",
  "Pinterest URL": "Pinterest URL",
  "The card has been set to primary": "The card has been set to primary",
  "An error occurred while setting the card to primary": "An error occurred while setting the card to primary",
  "The card has been removed successfully": "The card has been removed successfully",
  "An error occurred while removing the card": "An error occurred while removing the card",
  "Saved Payment Methods": "Saved Payment Methods",
  "Add new card": "Add new card",
  "Saved Cards": "Saved Cards",
  "No saved cards!": "No saved cards!",
  "Expires": "Expires",
  "Primary": "Primary",
  "Remove this card": "Remove this card",
  "Recent Transactions": "Recent Transactions",
  "Product information": "Product information",
  "Edit": "Edit",
  "Product image": "Product image",
  "Product type": "Product type",
  "Description": "Description",
  "Access instructions": "Access instructions",
  "Homepage": "Homepage",
  "Balance credit": "Balance credit",
  "View deals": "View deals",
  "Balance": "Balance",
  "Deals": "Deals",
  "Choose Content Creation Deal That Is Right For You": "Choose Content Creation Deal That Is Right For You",
  "Brand information": "Brand information",
  "Phone": "Phone",
  "Social URLs": "Social URLs",
  "Facebook": "Facebook",
  "Instagram": "Instagram",
  "Brand details": "Brand details",
  "Products": "Products",
  "Billing": "Billing",
  "Elli Creators - Brand": "Elli Creators - Brand",
  "Brand": "Brand",
  "Create": "Create",
  "No available products yet!": "No available products yet!",
  "URL": "URL",
  "Active Subscriptions": "Active Subscriptions",
  "View all subscriptions": "View all subscriptions",
  "High Growth": "High Growth",
  "due in": "due in",
  "days": "days",
  "Up to 50 videos": "Up to 50 videos",
  "Pay": "Pay",
  "Invite": "Invite",
  "Brand role": "Brand role",
  "Waiting for approval": "Waiting for approval",
  "My creators": "My creators",
  "No applications available. Yet!": "No applications available. Yet!",
  "Completed": "Completed",
  "Applied": "Applied",
  "Working": "Working",
  "Gender": "Gender",
  "No applications available yet!": "No applications available yet!",
  "The watermark logo is removed upon final video approval.": "The watermark logo is removed upon final video approval.",
  "Required # of creators": "Required # of creators",
  "Pending": "Pending",
  "Approved": "Approved",
  "Rejected": "Rejected",
  "Answers": "Answers",
  "Reject Creator": "Reject Creator",
  "This creator will be rejected! Are you sure?": "This creator will be rejected! Are you sure?",
  "Approve Creator": "Approve Creator",
  "Creator will be approved to a task.": "Creator will be approved to a task.",
  "Yes, I'm sure!": "Yes, I'm sure!",
  "Due to ship": "Due to ship",
  "Shipped": "Shipped",
  "Delivered": "Delivered",
  "Data added successfully!": "Data added successfully!",
  "No products delivery available yet!": "No products delivery available yet!",
  "Status": "Status",
  "Address": "Address",
  "Tracking": "Tracking",
  "Creator's action": "Creator's action",
  "Details": "Details",
  "Not received yet": "Not received yet",
  "Received damaged": "Received damaged",
  "Marked as received": "Marked as received",
  "Mark as shipped": "Mark as shipped",
  "Marked as shipped": "Marked as shipped",
  "Provide shipping details": "Provide shipping details",
  "Shipping to": "Shipping to",
  "Company name": "Company name",
  "Using a tracking number": "Using a tracking number",
  "Without a tracking number": "Without a tracking number",
  "Tracking number": "Tracking number",
  "Confirm": "Confirm",
  "Provide shipping details to": "Provide shipping details to",
  "All shipments": "All shipments",
  "Elli Creators - Delivery": "Elli Creators - Delivery",
  "No deliveries available yet!": "No deliveries available yet!",
  "Authentic videos from our video content creators.": "Authentic videos from our video content creators.",
  "Add your product and how you'd like to showcase it. We'll guide you every step of the way.": "Add your product and how you'd like to showcase it. We'll guide you every step of the way.",
  "Get video": "Get video",
  "Need inspiration?": "Need inspiration?",
  "Pre-filled video templates to help you get started.": "Pre-filled video templates to help you get started.",
  "Profile data saved successfully!": "Profile data saved successfully!",
  "Edit profile": "Edit profile",
  "Basic information": "Basic information",
  "Contact information": "Contact information",
  "Mobile number": "Mobile number",
  "Date of birth": "Date of birth",
  "City": "City",
  "Street": "Street",
  "State": "State",
  "My profile": "My profile",
  "Elli Creators - Profile Details": "Elli Creators - Profile Details",
  "Where your video ad will be displayed": "Where your video ad will be displayed",
  "Tiktok": "Tiktok",
  "Youtube": "Youtube",
  "Snapchat": "Snapchat",
  "Twitter": "Twitter",
  "Amazon": "Amazon",
  "Elli Creators - Creating Task": "Elli Creators - Creating Task",
  "TASK": "TASK",
  "ORDER": "ORDER",
  "BRIEF": "BRIEF",
  "You must agree to our terms and conditions": "You must agree to our terms and conditions",
  "You have to add a product!": "You have to add a product!",
  "will be invited to this task": "will be invited to this task",
  "Re-ordering from the previous video: options are pre-selected but feel free to change them.": "Re-ordering from the previous video: options are pre-selected but feel free to change them.",
  "Revoke invitation": "Revoke invitation",
  "Save draft": "Save draft",
  "Next step": "Next step",
  "Creator criteria": "Creator criteria",
  "Age range": "Age range",
  "Looking for anything specific? Describe creator qualities (e.g. has a pet / can film in a car)": "Looking for anything specific? Describe creator qualities (e.g. has a pet / can film in a car)",
  "Invite creators I worked with in the past to apply for this task (if they fit the criteria)": "Invite creators I worked with in the past to apply for this task (if they fit the criteria)",
  "Delivery": "Delivery",
  "Doorstep delivery": "Doorstep delivery",
  "The brand will take care of delivering the product directly to your doorstep, making it a stress-free experience for the creator.": "The brand will take care of delivering the product directly to your doorstep, making it a stress-free experience for the creator.",
  "Schedule a visit": "Schedule a visit",
  "The creator will visit the brand's designated location for a photoshoot, allowing for a more controlled and authentic environment to showcase the product.": "The creator will visit the brand's designated location for a photoshoot, allowing for a more controlled and authentic environment to showcase the product.",
  "Option": "Option",
  "Estimated video delivery": "Estimated video delivery",
  "Show less": "Show less",
  "See delivery breakdown": "See delivery breakdown",
  "Creator approval": "Creator approval",
  "by": "by",
  "Product shipped to creator": "Product shipped to creator",
  "Note: video delivery may be delayed by product and creator logistics": "Note: video delivery may be delayed by product and creator logistics",
  "Creators receive products, produce and submit videos": "Creators receive products, produce and submit videos",
  "You review and approve videos": "You review and approve videos",
  "If edits are needed, expect videos between": "If edits are needed, expect videos between",
  "and": "and",
  "I agree to": "I agree to",
  "terms and conditions": "terms and conditions",
  "Privacy Policy": "Privacy Policy",
  "I understand that there may be a delay caused by product logistics, my approvals or edit requests.": "I understand that there may be a delay caused by product logistics, my approvals or edit requests.",
  "You have to accept the terms & conditions": "You have to accept the terms & conditions",
  "Number of videos": "Number of videos",
  "Digital marketers see best results with 4-6 creatives per ad campaign!": "Digital marketers see best results with 4-6 creatives per ad campaign!",
  "How many videos you'd like to order?": "How many videos you'd like to order?",
  "Order will be carried out by": "Order will be carried out by",
  "Your product access": "Your product access",
  "Make sure you have provided all the necessary access instructions when creating your product. The faster and more detailed you note the access instructions, the faster you can expect to receive the final videos.": "Make sure you have provided all the necessary access instructions when creating your product. The faster and more detailed you note the access instructions, the faster you can expect to receive the final videos.",
  "Please Note that": "Please Note that",
  "You will need to provide your product to each selected creator.": "You will need to provide your product to each selected creator.",
  "Currently we work with creators located in Jordan only": "Currently we work with creators located in Jordan only",
  "Order summary": "Order summary",
  "share video": "share video",
  "Total": "Total",
  "Your product": "Your product",
  "More products": "More products",
  "Create a new product": "Create a new product",
  "All your products": "All your products",
  "Select": "Select",
  "Product name pronunciation (optional)": "Product name pronunciation (optional)",
  "Record how to pronounce your brand & product names so the creator can say them correctly when shooting a video.": "Record how to pronounce your brand & product names so the creator can say them correctly when shooting a video.",
  "Pause": "Pause",
  "Start": "Start",
  "Stop": "Stop",
  "Ask creators about": "Ask creators about",
  "size": "size",
  "color": "color",
  "product options": "product options",
  "Question": "Question",
  "Add question": "Add question",
  "Brand name": "Brand name",
  "Do not mention brand name": "Do not mention brand name",
  "Easy to use": "Easy to use",
  "Saves time": "Saves time",
  "Game changer": "Game changer",
  "Changed my life/routine": "Changed my life/routine",
  "Free shipping": "Free shipping",
  "50% off": "50% off",
  "30% off": "30% off",
  "20% off": "20% off",
  "Limited time offer": "Limited time offer",
  "Buy one, get ...": "Buy one, get ...",
  "Don't miss out": "Don't miss out",
  "Shop now": "Shop now",
  "Sign up": "Sign up",
  "Join for free": "Join for free",
  "Subscribe": "Subscribe",
  "Book now": "Book now",
  "Creative mode": "Creative mode",
  "Simplified scenario - give creators freedom on how they showcase your product": "Simplified scenario - give creators freedom on how they showcase your product",
  "Scenes describe what can be seen in a video.": "Scenes describe what can be seen in a video.",
  "Scenes": "Scenes",
  "Add a scenario": "Add a scenario",
  "Anything the creator should mention or highlight": "Anything the creator should mention or highlight",
  "Mentions": "Mentions",
  "Brand & product": "Brand & product",
  "Product feature": "Product feature",
  "Promo": "Promo",
  "Call-To-Action": "Call-To-Action",
  "Mention": "Mention",
  "Add mention": "Add mention",
  "Anything that would help the creator deliver a perfect video": "Anything that would help the creator deliver a perfect video",
  "Please provide links to the content you like": "Please provide links to the content you like",
  "Link": "Link",
  "Add link": "Add link",
  "I would like the creator to distribute the video to their audience. (+ $24.99)": "I would like the creator to distribute the video to their audience. (+ $24.99)",
  "Video duration": "Video duration",
  "Choose video format": "Choose video format",
  "Horizontal": "Horizontal",
  "Display ads in Youtube, Twitter and Amazon": "Display ads in Youtube, Twitter and Amazon",
  "Square": "Square",
  "Carousel and Instagram Shop ads": "Carousel and Instagram Shop ads",
  "Vertical": "Vertical",
  "Feed placement ads": "Feed placement ads",
  "Stories, Reels or Shorts": "Stories, Reels or Shorts",
  "Video type": "Video type",
  "Video ad": "Video ad",
  "Flexible ad for any digital product or a service": "Flexible ad for any digital product or a service",
  "Grow views with organic TikTok videos or turn them into TikTok Spark Ads.": "Grow views with organic TikTok videos or turn them into TikTok Spark Ads.",
  "Reveal benefits of your app with an over-the-shoulder device shot": "Reveal benefits of your app with an over-the-shoulder device shot",
  "Testimonial": "Testimonial",
  "Benefits of your app in a split screen video or from a point of view.": "Benefits of your app in a split screen video or from a point of view.",
  "How-to": "How-to",
  "Explainer video for your product.": "Explainer video for your product.",
  "Unboxing": "Unboxing",
  "Display the unboxing experience of your product.": "Display the unboxing experience of your product.",
  "Explainer": "Explainer",
  "Run-through your app to explain how it works and what it does.": "Run-through your app to explain how it works and what it does.",
  "Preview": "Preview",
  "Intro video for the app store listing.": "Intro video for the app store listing.",
  "360 showcase": "360 showcase",
  "Show your product from every angle.": "Show your product from every angle.",
  "Honest review": "Honest review",
  "A genuine opinion about your product from your target audience.": "A genuine opinion about your product from your target audience.",
  "Invoice": "Invoice",
  "The Elli Creators": "The Elli Creators",
  "Amman, Jordan": "Amman, Jordan",
  "Invoice for": "Invoice for",
  "Issued on": "Issued on",
  "Due on": "Due on",
  "Balance due": "Balance due",
  "Item": "Item",
  "Qty": "Qty",
  "Discount": "Discount",
  "Download PDF": "Download PDF",
  "Preview PDF": "Preview PDF",
  "Elli Creators - Edit Task": "Elli Creators - Edit Task",
  "Deal added successfully!": "Deal added successfully!",
  "Submit secure payment": "Submit secure payment",
  "Here's your order summary": "Here's your order summary",
  "Editing services": "Editing services",
  "Order total": "Order total",
  "Enter promo code": "Enter promo code",
  "Code applied successfully!": "Code applied successfully!",
  "Promo code": "Promo code",
  "Apply": "Apply",
  "Remove code": "Remove code",
  "On average, an agency-produced UGC video costs around $500! We offer the same (some even say better!) quality videos for a fraction of that price.": "On average, an agency-produced UGC video costs around $500! We offer the same (some even say better!) quality videos for a fraction of that price.",
  "You pay for your videos up front. This assures creators you're able to pay when they create content and you approve it.": "You pay for your videos up front. This assures creators you're able to pay when they create content and you approve it.",
  "Payment Methods": "Payment Methods",
  "Select an existing payment method": "Select an existing payment method",
  "Add a new payment method": "Add a new payment method",
  "Quality guaranteed": "Quality guaranteed",
  "edit requests for each video": "edit requests for each video",
  "Refund if you won't find creators": "Refund if you won't find creators",
  "Brief": "Brief",
  "General information": "General information",
  "Consideration (engagements / conversation / video views)": "Consideration (engagements / conversation / video views)",
  "In progress": "In progress",
  "In review": "In review",
  "Error": "Error",
  "Product details": "Product details",
  "Video specifications": "Video specifications",
  "Order": "Order",
  "Duration": "Duration",
  "Aspect ratio": "Aspect ratio",
  "Female": "Female",
  "Male": "Male",
  "Any": "Any",
  "Giveaway": "Giveaway",
  "Product giveaway": "Product giveaway",
  "Items will be given away": "Items will be given away",
  "Giveaway value": "Giveaway value",
  "Video pricing": "Video pricing",
  "Total budget": "Total budget",
  "Application approved successfully!": "Application approved successfully!",
  "No videos available yet!": "No videos available yet!",
  "Card content": "Card content",
  "Rate video": "Rate video",
  "Re-order": "Re-order",
  "Download": "Download",
  "Applications": "Applications",
  "Product delivery": "Product delivery",
  "Elli Creators - Task Details": "Elli Creators - Task Details",
  "Elli Creators - Tasks": "Elli Creators - Tasks",
  "Task duplicated successfully!": "Task duplicated successfully!",
  "Videos approved": "Videos approved",
  "Shipping": "Shipping",
  "Canceled": "Canceled",
  "Content": "Content",
  "Request edits": "Request edits",
  "STATUS": "STATUS",
  "Visible to Client": "Visible to Client",
  "Quality Check passed": "Quality Check passed",
  "Download video": "Download video",
  "Please note that requesting edits will delay the video delivery time by 2-5 business days.": "Please note that requesting edits will delay the video delivery time by 2-5 business days.",
  "Edits submitted successfully, and application has been approved!": "Edits submitted successfully, and application has been approved!",
  "Change": "Change",
  "Add request": "Add request",
  "My content": "My content",
  "Elli Creators - Videos": "Elli Creators - Videos",
  "Total videos": "Total videos",
  "Get more": "Get more",
  "Help": "Help",
  "improve": "improve",
  "Rate this video": "Rate this video",
  "I'll do this later": "I'll do this later",
  "Elli Creators - FAQ's": "Elli Creators - FAQ's",
  "How does Elli work?": "How does Elli work?",
  "Who owns the content?": "Who owns the content?",
  "Rest assured, the brands retain full ownership of the content they purchase.": "Rest assured, the brands retain full ownership of the content they purchase.",
  "What is an Honest review?": "What is an Honest review?",
  "The ultimate way to gain authentic insights. Discover firsthand the pros and cons of your product, ensuring an honest and valuable perspective.": "The ultimate way to gain authentic insights. Discover firsthand the pros and cons of your product, ensuring an honest and valuable perspective.",
  "What is an Unboxing?": "What is an Unboxing?",
  "Unboxing videos typically feature the product's packaging and the exciting unveiling process.": "Unboxing videos typically feature the product's packaging and the exciting unveiling process.",
  "What happens if I did not find the creators I want to work with?": "What happens if I did not find the creators I want to work with?",
  "In such instances, our team can assist you by personally inviting additional creators to join your project. We value your input on what specific qualities or skills you desire in a creator. Furthermore, broadening your target audience often leads to a higher number of applications, providing you with a larger talent pool of creators to select from.": "In such instances, our team can assist you by personally inviting additional creators to join your project. We value your input on what specific qualities or skills you desire in a creator. Furthermore, broadening your target audience often leads to a higher number of applications, providing you with a larger talent pool of creators to select from.",
  "Elli Creators - Login": "Elli Creators - Login",
  "Sign in": "Sign in",
  "Fill in the fields below to sign into your account.": "Fill in the fields below to sign into your account.",
  "The email field is required": "The email field is required",
  "The password field is required": "The password field is required",
  "Email address": "Email address",
  "Password": "Password",
  "I accept the": "I accept the",
  "Don’t have an account, yet?": "Don’t have an account, yet?",
  "Sign up here": "Sign up here",
  "Elli Creators - Recover Password": "Elli Creators - Recover Password",
  "Recover Password": "Recover Password",
  "Enter the email used for registration to reset your password.": "Enter the email used for registration to reset your password.",
  "Send me a new password": "Send me a new password",
  "Want to try to sign in again?": "Want to try to sign in again?",
  "Click here": "Click here",
  "The password reset instructions have been sent to your email": "The password reset instructions have been sent to your email",
  "Check your email for further instructions": "Check your email for further instructions",
  "Continue to login": "Continue to login",
  "Enter your brand information to get started": "Enter your brand information to get started",
  "Complete registration": "Complete registration",
  "What business are you in?": "What business are you in?",
  "Physical products": "Physical products",
  "Digital products": "Digital products",
  "How did you find out about Elli?": "How did you find out about Elli?",
  "Done": "Done",
  "Elli Creators - Register": "Elli Creators - Register",
  "Fill in the fields below to sign up for an account.": "Fill in the fields below to sign up for an account.",
  "Create account": "Create account",
  "Already have an account?": "Already have an account?",
  "Sign in here": "Sign in here",
  "Create your account": "Create your account",
  "Elli Creators - Coming Soon": "Elli Creators - Coming Soon"
};

export default enJSON;
